import React from "react"
import { StaticQuery, graphql } from "gatsby"
import { Link } from "gatsby"
//import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"

import "../styles/about.css"

const About = () => (
  <StaticQuery
    query={graphql`
      query AboutImageQuery{
        placeholderImage: file(relativePath: { eq: "images/akinul-photo.jpg" }) {
          childImageSharp {
            fluid(maxWidth: 300) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `}
    render={ data => (
      <Layout>
        <SEO title="About" keywords={[`akinul.com`, `Akinul Islam Jony`]}/>
        {/* To display the image of Akinul  */}
        {/* <div id="aboutImageContainer">
          <Img 
            className="aboutImage"
            fluid={data.placeholderImage.childImageSharp.fluid} 
          />
        </div> */}
        <div id="aboutText">
          <p>
            Hey there, I'm <span className="specialWord">Akinul</span>. Welcome to my site!
          </p>    
          <p>
            Here I post articles based on tech, programming, and data science.
          </p> 
          <p>
            To see the different categories of my posts or articles, 
            or what types of article I have written and posted so far, please check <Link to="/category/" className="linkAbout">Category List</Link>.
          </p>    
          <p>
            I would love to hear from you. To write me just fill-up the <Link to="/contact/" className="linkAbout">Contact</Link> form
            and send your message. I will get back to you ASAP.
          </p>           
          {/* <p>
            Here I post articles based on tech and programming, 
            especially related
            to <Link to="/categories/python/" className="linkAbout">python</Link>, 
            &nbsp;<Link to="/categories/django/" className="linkAbout">django</Link>, 
            &nbsp;<Link to="/categories/javascript/" className="linkAbout">javascript</Link>, 
            &nbsp;<Link to="/categories/react/" className="linkAbout">react</Link>,
            and&nbsp;<Link to="/categories/gatsby/" className="linkAbout">gatsby</Link>.
          </p> */}
          {/* <p>
            I am an avid learner. My personal philosophy 
            is <span className="specialWord">learn-share-learn</span>, because 
            I believe <em>sharing</em> is one of the handy way of 
            learning. Not only that, it also helps me to stay motivated, and learn and listen from others. 
          </p> */}
          <p>Thanks for visiting my site.</p>
          <p>Big Love.</p>
        </div>
        <div id="aboutSignature">
          - Akinul Islam Jony
        </div>
    </Layout>
    )}
  />
)

export default About

// Ignore following words from checking by Code Spell Checker
// cSpell:ignore akinul jony